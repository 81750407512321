import React, { useState } from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useLocation } from '@reach/router';
import { motion } from 'framer-motion';

import Layout from '@components/layout';
import SEO from '@components/seo';

import { StandardModal } from '@modules/standardModal';
import { useSiteConfig } from '@querys/useSiteConfig';

const Terms = ({ toggleOpen }) => {
  const { termsPopup } = useSiteConfig();
  return (
    <div className='popup terms grid grid-cols-12 bg-liaise-orange pt-14 pb-20'>
      <button
        onClick={toggleOpen}
        className='col-start-11 col-end-12 flex justify-end items-start focus:outline-none'
      >
        <svg
          className='w-max'
          width='37'
          height='37'
          viewBox='0 0 37 37'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M0 4.04687L4.04687 0L18.5 14.4531L32.9531 0L37 4.04687L22.5469 18.5L37 32.9531L32.9531 37L18.5 22.5469L4.04687 37L0 32.9531L14.4531 18.5L0 4.04687Z'
            fill='#FBEF89'
          />
        </svg>
      </button>
      <h2 className='font-extrabold col-start-2 col-end-11 md:row-start-1'>
        {termsPopup.heading}
      </h2>

      <div className='text-base leading-base md:text-xl md:leading-xl col-start-2 col-end-12 mt-8 md:mt-14'>
        <span className='font-extrabold'>{termsPopup.subheading}</span>
        <ol className='font-semibold list-decimal ml-4 md:ml-8 mt-4 md:mt-8'>
          {termsPopup.terms.map((t, i) => (
            <li key={i}>{t}</li>
          ))}
        </ol>
      </div>
    </div>
  );
};

const CompForm = ({ toggleOpen }) => {
  const { pathname } = useLocation();

  function encode(data) {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
      )
      .join('&');
  }

  const inputClasses = `block w-full bg-liaise-yellow p-3 md:p-5 text-base md:text-lg text-liaise-brown font-semibold focus:outline-none`;
  const errorClasses = `text-liaise-brown text-base leading-base font-semibold`;

  return (
    <Formik
      initialValues={{
        name: '',
        address: '',
        email: '',
        phone: '',
        agree: '',
        concert: '',
      }}
      validate={(values) => {
        const errors = {};
        if (!values.name) {
          errors.name = 'Full Name is Required';
        }

        if (!values.address) {
          errors.address = 'Address is Required';
        }

        if (!values.email) {
          errors.email = 'Email address is Required';
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
          errors.email = 'Invalid email address';
        }

        if (!values.phone) {
          errors.phone = 'Phone Number is Required';
        }

        if (!values.concert) {
          errors.concert = 'Concert name is Required';
        }

        if (!values.agree) {
          errors.agree = 'You must agree to the Terms & Conditions to enter';
        }

        return errors;
      }}
      onSubmit={(values, { setSubmitting, setStatus }) => {
        fetch(pathname || '/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: encode({
            'form-name': `grassroots-tour-entries`,
            form_name: `grassroots-tour-entries`,
            ...values,
          }),
        })
          .then(() => {
            setSubmitting(false);
            setStatus('success');
          })
          .catch((error) => alert(error));
      }}
    >
      {({
        onChange,
        isSubmitting,
        status,
        values,
        /* and other goodies */
      }) => (
        <Form
          className='w-full relative flex flex-col justify-center items-center'
          name='grassroots-tour-entries'
          data-netlify='true'
          data-netlify-honeypot='bot-field'
          subject='Competition Entry'
          style={{ maxWidth: '711px' }}
        >
          {/* Hidden Fields for netlify */}
          <input
            type='hidden'
            name='form-name'
            value='grassroots-tour-entries'
          />
          <p hidden>
            <label>
              <input name='bot-field' onChange={onChange} />
            </label>
          </p>
          <>
            <div
              className={`font-body absolute top-1/2 transition-all delay-300 duration-300 left-0 right-0 z-10 ${
                status === 'success'
                  ? 'opacity-100 pointer-events-all'
                  : 'opacity-0 pointer-events-none'
              }`}
            >
              <motion.h2
                className={`text-center text-liaise-yellow title font-black`}
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
              >
                Good luck, you're in the draw!
              </motion.h2>
              <Link
                className='underline mt-5 font-bold text-center block'
                to='/'
              >
                Back to Liaise website
              </Link>
            </div>
            <div
              className={`w-full mt-4 lg:mt-6 transition-all duration-300 ${
                status === 'success' && 'opacity-0 pointer-events-none'
              }`}
            >
              <h1 className='text-center my-12 md:my-20 title font-black'>
                Enter the draw to WIN 2x tickets to SIX60 Grassroots Tour.
              </h1>
              <div className='flex flex-col gap-y-4 md:gap-y-6'>
                <Field
                  name='name'
                  className={inputClasses}
                  id='name'
                  type='text'
                  placeholder='Full Name'
                />
                <ErrorMessage
                  name='name'
                  className={errorClasses}
                  component='div'
                />

                <Field
                  name='address'
                  className={inputClasses}
                  id='address'
                  type='text'
                  placeholder='Address'
                />
                <ErrorMessage
                  name='address'
                  className={errorClasses}
                  component='div'
                />

                <Field
                  name='email'
                  className={inputClasses}
                  id='email'
                  type='text'
                  placeholder='Email Address'
                />
                <ErrorMessage
                  name='email'
                  className={errorClasses}
                  component='div'
                />

                <Field
                  name='phone'
                  className={inputClasses}
                  id='phone'
                  type='text'
                  placeholder='Phone Number'
                />

                <ErrorMessage
                  name='phone'
                  className={errorClasses}
                  component='div'
                />
                <Field
                  name='concert'
                  className={inputClasses}
                  id='concert'
                  type='text'
                  placeholder='Concert you would like to Win tickets for'
                />

                <ErrorMessage
                  name='concert'
                  className={errorClasses}
                  component='div'
                />
              </div>

              <div className='underline mt-4 font-bold text-sm md:text-base'>
                <a
                  href='https://six60.co.nz/pages/upcoming-gigs'
                  target='_blank'
                >
                  Click to view Six60 Sessions concert page
                </a>
              </div>

              <div className='flex flex-col-reverse md:flex-row gap-x-8 mt-12 text-sm md:text-base leading-sm md:leading-base font-semibold text-center md:text-left'>
                <div className='flex flex-col gap-y-5 w-full'>
                  <div>
                    <span className='block'>
                      You must be a NZ resident 18+ to enter. Final entries
                      close midday 07.07.24.
                    </span>
                    <span className='block mt-2'>
                      This is a Premium Liquor promotion.
                    </span>
                    {/* <span className="mt-2 block">For full terms and Conditions click <span onClick={toggleOpen} className="underline cursor-pointer">here</span>.</span> */}
                    <span className='mt-2 block'>
                      For full terms and Conditions click{' '}
                      <Link to='/terms' className='underline cursor-pointer'>
                        here
                      </Link>
                      .
                    </span>
                  </div>
                  <div className='flex items-center justify-center md:justify-start'>
                    <Field
                      type='checkbox'
                      name='agree'
                      id='agree'
                      className='agree ml-2'
                    />
                    <span className='ml-6 text-left'>
                      I agree to the{' '}
                      <Link to='/terms' className='underline'>
                        Terms & Conditions
                      </Link>{' '}
                      and{' '}
                      <Link to='/privacy' className='underline'>
                        Privacy Policy
                      </Link>
                    </span>
                  </div>
                  <ErrorMessage
                    name='agree'
                    className={errorClasses}
                    component='div'
                  />

                  <button
                    type='submit'
                    className='mt-2 block submit w-full py-5 bg-liaise-yellow text-liaise-brown text-3xl leading-3xl font-extrabold'
                  >
                    SUBMIT ENTRY
                  </button>
                  <Link className='underline mt-5 font-bold' to='/'>
                    Back to Liaise website
                  </Link>
                </div>
              </div>
            </div>
          </>
        </Form>
      )}
    </Formik>
  );
};

const JetPlane = () => {
  const [open, setOpen] = useState(false);
  const toggleOpen = () => setOpen((prev) => !prev);
  return (
    <Layout>
      <SEO title='Promo' />
      <div className='jetplane grid grid-cols-14 bg-liaise-orange pb-12 md:pb-80'>
        <div className='col-start-1 col-end-15  mx-4 mt-4 md:mx-12 md:mt-12'>
          <StaticImage src={'../images/grassroots-banner.png'} />
        </div>

        <div className='col-start-1 col-end-15 mx-4 md:mx-auto'>
          <CompForm toggleOpen={toggleOpen} />
        </div>
      </div>

      <StandardModal isOpen={open}>
        {open && <Terms toggleOpen={toggleOpen} />}
      </StandardModal>
    </Layout>
  );
};

export default JetPlane;
